import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image/withIEPolyfill"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Wrapper,
  NewsText,
  NewsImg,
  Markdown,
  NewsWrapper,
  Display,
  Headline,
  Video,
} from "../styles"

const work = ({ data }) => {
  const work = data.strapiWorks

  return (
    <Layout>
      <SEO title="Works" />
      <Display center>{work.title}</Display>

      <NewsWrapper row alignCenter justifyEvenly>
        {work.intro && (
          <NewsText>
            <Markdown>
              <ReactMarkdown source={work.intro} escapeHtml={false} />
            </Markdown>
          </NewsText>
        )}
        {work.picture && (
          <NewsImg>
            <Img
              style={{ borderRadius: "5px", marginTop: "10px" }}
              fluid={work.picture.childImageSharp.fluid}
            />
          </NewsImg>
        )}
      </NewsWrapper>
      {work.audio_link && (
        <NewsText style={{ margin: "60px auto 40px" }}>
          <iframe
            width="100%"
            title={work.title}
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${work.audio_link}&color=%23588fe6&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
          ></iframe>
        </NewsText>
      )}
      {work.video_link && (
        <Wrapper alignCenter style={{ margin: "40px 0" }}>
          <Headline>Video:</Headline>
          <Video>
            <ReactPlayer
              url={work.video_link}
              width="100%"
              height="100%"
              controls
            />
          </Video>
        </Wrapper>
      )}
      {work.video_link_2 && (
        <Wrapper alignCenter style={{ margin: "0 0 60px" }}>
          <Video>
            <ReactPlayer
              url={work.video_link_2}
              width="100%"
              height="100%"
              controls
            />
          </Video>
        </Wrapper>
      )}
      {work.text && (
        <Markdown style={{ margin: "40px 0" }}>
          <Headline center>Notes:</Headline>
          <ReactMarkdown source={work.text} escapeHtml={false} />
        </Markdown>
      )}
    </Layout>
  )
}

export default work

export const workQuery = graphql`
  query($location: String!) {
    strapiWorks(title: { eq: $location }) {
      intro
      text
      title
      audio_link
      video_link
      video_link_2
      picture {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
